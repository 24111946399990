/* circle.css */
h4 {
  font-size: 2em;
  font-weight: bold;
}

@media (max-width: 500px) {
  .circle-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 60px; /* Adjust the gap as needed */
    justify-items: center;
    align-items: center;
  }
  .circle-container {
    margin: 0 10vw; /* Equal left and right margins */
  }
}

.App.light .circle-section {
  margin-left: 20vw;
  margin-right: 20vw;
}

.App.dark .circle-section {
  margin-left: 20vw;
  margin-right: 20vw;
}

.circle-progress {
  text-align: center;
  flex: 1;
  /* Distribute the available space evenly */
}

.App.light .circle-background {
  fill: none;
  stroke: var(--light-accent-opposite-color);
  /* Background color */
  stroke-width: 10;
  /* Adjust the stroke width as needed */
}

.App.dark .circle-background {
  fill: none;
  stroke: var(--dark-accent-opposite-color);
  /* Background color */
  stroke-width: 10;
  /* Adjust the stroke width as needed */
}

.App.light .circle-bar {
  fill: none;
  stroke: var(--light-circle-color);
  /* Border color based on progress */
  stroke-width: 10;
  /* Adjust the border width as needed */
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease;
  /* Add a smooth animation */
}

.App.dark .circle-bar {
  fill: none;
  stroke: var(--dark-circle-color);
  /* Border color based on progress */
  stroke-width: 10;
  /* Adjust the border width as needed */
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease;
  /* Add a smooth animation */
}

.App.light .circle-text {
  font-size: 1em;
  /* Adjust the font size as needed */
  font-weight: bold;
  fill: var(--light-text-color);
  /* Text color */
}

.App.dark .circle-text {
  font-size: 1em;
  /* Adjust the font size as needed */
  font-weight: bold;
  fill: var(--dark-text-color);
  /* Text color */
}

.App.light .circle-label {
  font-weight: bold;
  margin-top: 0.4em;
  color: var(--light-text-color);
}

.App.dark .circle-label {
  font-weight: bold;
  margin-top: 0.4em;
  color: var(--dark-text-color);
}

@media (min-width: 800px) {
  /* circle.css */
  .App.light .circle-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 20vw);
    /* Subtract 40px (20vw) from the viewport width for margins */
    height: 20vh;
    /* Set the height to 100vh to occupy the entire viewport height */
    box-sizing: border-box;
    /* Include the border in the section's total width and height */
    border: 0.15em solid var(--light-border-color);
    /* Add a 2px border with a custom color */
    border-radius: 1em;
    padding-top: 20px;
  }
  .App.dark .circle-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 20vw);
    /* Subtract 40px (20vw) from the viewport width for margins */
    height: 20vh;
    /* Set the height to 100vh to occupy the entire viewport height */
    box-sizing: border-box;
    /* Include the border in the section's total width and height */
    border: 0.15em solid var(--dark-border-color);
    /* Add a 2px border with a custom color */
    border-radius: 1em;
  }
  .circle-section h4 {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 40vh;
    position: absolute;
  }

  .circle-progress-container {
    display: flex;
    justify-content: space-between;
    /* Adjust the alignment as needed */
    align-items: center;
    flex-wrap: nowrap;
    /* Prevent wrapping to keep them horizontal */
  }

  .circle-progress {
    text-align: center;
    flex: 1;
    /* Distribute the available space evenly */
  }

  .circle-background {
    fill: none;
    stroke-width: 15;
    /* Adjust the stroke width as needed */
  }

  .circle-bar {
    fill: none;
    stroke-width: 15;
    /* Adjust the border width as needed */
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease;
    /* Add a smooth animation */
  }

  .circle-text {
    font-size: 1.3em;
    /* Adjust the font size as needed */
    font-weight: bold;
  }

  .circle-label {
    font-weight: bold;
    margin-top: 1em;
  }
}

@media (min-width: 2000px) and (min-height: 1000px) {
  /* circle.css */
  .App.light.circle-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 20vw);
    /* Subtract 40px (20vw) from the viewport width for margins */
    height: 20vh;
    /* Set the height to 100vh to occupy the entire viewport height */
    box-sizing: border-box;
    /* Include the border in the section's total width and height */
    border: 0.15em solid var(--light-border-color);
    /* Add a 2px border with a custom color */
    border-radius: 1em;
  }
  .App.dark .circle-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 20vw);
    /* Subtract 40px (20vw) from the viewport width for margins */
    height: 20vh;
    /* Set the height to 100vh to occupy the entire viewport height */
    box-sizing: border-box;
    /* Include the border in the section's total width and height */
    border: 0.15em solid var(--dark-border-color);
    /* Add a 2px border with a custom color */
    border-radius: 1em;
  }

  .circle-section h4 {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 35vh;
    position: absolute;
  }

  .circle-progress-container {
    display: flex;
    justify-content: space-between;
    /* Adjust the alignment as needed */
    align-items: center;
    flex-wrap: nowrap;
    /* Prevent wrapping to keep them horizontal */
  }

  .circle-progress {
    text-align: center;
    flex: 1;
    /* Distribute the available space evenly */
  }

  .circle-background {
    fill: none;
    stroke-width: 15;
    /* Adjust the stroke width as needed */
  }

  .circle-bar {
    fill: none;
    stroke-width: 15;
    /* Adjust the border width as needed */
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease;
    /* Add a smooth animation */
  }

  .circle-text {
    font-size: 1.3em;
    /* Adjust the font size as needed */
    font-weight: bold;
  }

  .circle-label {
    font-weight: bold;
    margin-top: 0.75em;
  }
}
