/* Navbar.css */
/* CSS For Nav bar */
.navbar {
    z-index: 1000; /* You can adjust the value as needed */
  }
  
.App.light .navbar {
    background-color: var(--light-background);
    color: var(--light-text-color);
    position: fixed;
    /* Fixed position */
    bottom: 0;
    /* Stick it to the bottom */
    width: 100%;
}

.App.dark .navbar {
    background-color: var(--dark-background);
    color: var(--dark-text-color);
    position: fixed;
    /* Fixed position */
    bottom: 0;
    /* Stick it to the bottom */
    width: 100%;
}

.navbar ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    padding: 2.5vh;
    margin: 0;
}

.navbar li {
    margin: 0;
}

.navbar a {
    text-decoration: none; /* Remove underline for visited links */
    color: inherit; /* Inherit the text color from the parent element */
}

.navbar a:visited {
    text-decoration: none; /* Remove underline for visited links */
    color: inherit; /* Inherit the text color from the parent element */
}

/* CSS for containers */
.icon-container {
    display: flex;
    align-items: center;
    position: relative;
}

.icon-text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Add transitions for opacity and transform */
}

.nav-icon {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.icon-link:hover .nav-icon {
    opacity: 0; /* Hide the icon during hover */
}

.icon-link:hover .icon-text {
    opacity: 1; /* Show the text during hover */
    transform: translateX(-50%) translateY(0); /* Move the text to the same position as the icon */
}

/* Phone CSS */
@media screen and (max-width: 799px) {

    /* CSS for Nav text */
    .App.light .navbar a {
        text-decoration: none;
        color: var(--light-text-color);
        font-size: 1em;
        /* Set the font size to 3em */
        font-weight: bold;

    }

    .App.dark .navbar a {
        text-decoration: none;
        color: var(--dark-text-color);
        font-size: 1em;
        /* Set the font size to 3em */
        font-weight: bold;

    }
}

@media (min-width: 800px) {
    .navbar a {
        font-size: 2em;
        /* Set the font size to 3em */
        font-weight: bold;

    }
}
