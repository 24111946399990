/* timeline.css */

/* Set the height of the page to 100vh */
.Timeline {
  height: 100vh;
  display: flex;
  flex-direction: column; /* Ensure a vertical column layout */
}

/* Style for the timeline header */
.timeline-header {
  font-size: 2em;
  order: 1; /* Place the header at the top */
}

.App.light .vertical-timeline-element-date {
  color: var(--light-text-color);
  font-size: 1.25em;
}
.App.dark .vertical-timeline-element-date {
  color: var(--dark-text-color);
  font-size: 1.25em;
}
.vertical-timeline-element-subtitle {
  font-size: 1.5em;
}
.App.light .vertical-timeline-element-subtitle {
  color: var(--light-text-color);
}
.App.dark .vertical-timeline-element-subtitle {
  color: var(--dark-text-color);
}

.vertical-timeline-element-text {
  font-size: 1.25rem;
  font-weight: 500;
}

/* Style for the timeline container */
.timeline-container {
  padding: 20px;
  overflow-y: scroll;
  /* Add scrollbars if the content overflows */
  flex: 1; /* Allow the container to grow and fill available space */
}

.App.light .timeline-header {
  color: var(--light-text-color);
}

.App.dark .timeline-header {
  color: var(--dark-text-color);
}

/* CSS properties for VerticalTimeline */
.App.light .vertical-timeline {
  color: var(--light-text-color);
}

.App.light .vertical-timeline-element-icon {
  /* Add your CSS styles for the icon here */
  background: var(--light-accent-color);
  box-shadow: 0 0 0 0.3em var(--light-text-color);
}

.App.dark .vertical-timeline-element-icon {
  /* Add your CSS styles for the icon here */
  background: var(--dark-accent-color);
  box-shadow: 0 0 0 0.3em var(--dark-border-color);
}

.App.light .vertical-timeline-element-content {
  border: 0.25rem solid var(--light-text-color);
  border-radius: 0.5em;
  background-color: var(--light-background);
  color: var(--light-text-color);
  box-shadow: none;
}
.App.light .vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 1vw;
  right: 100%;
  height: 0;
  width: 0;
  border: 15px solid transparent;
  border-right: 15px solid var(--light-text-color);
}

.App.dark .vertical-timeline-element-content {
  border: 0.25rem solid var(--dark-border-color);
  border-radius: 0.5em;
  background-color: var(--dark-background);
  color: var(--dark-accent-color);
  box-shadow: none;
}
.App.dark .vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 1vw;
  right: 100%;
  height: 0;
  width: 0;
  border: 15px solid transparent;
  border-right: 15px solid var(--dark-border-color);
}

.App.light .vertical-timeline::before {
  --line-color: var(--light-text-color);
}
.App.dark .vertical-timeline::before {
  --line-color: var(--dark-border-color);
}
/* Add more CSS properties for other elements as needed */


