/* about.css */
.about{
  margin-bottom: 25vh;
}

.skills {
  text-align: center;
}

.skills h3 {
  font-size: 2.5em;
  font-weight: bold;
}

.skills-container {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-gap: 5vh; /* Adjust the gap as needed */
  margin-bottom: 5vh;
}

.skills-section h4 {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.skill {
  width: 100%;
  text-align: left;
}

.App.light .progress-bar {
  height: 1vh;
  background-color: var(--light-accent-opposite-color);
  border-radius: 0.4em;
  margin-top: 0.6em;
}

.App.dark .progress-bar {
  height: 1vh;
  background-color: var(--dark-accent-opposite-color);
  border-radius: 5px;
  margin-top: 5px;
}

.App.light .progress {
  height: 100%;
  border-radius: 5px;
  background-color: var(--light-accent-color);
}

.App.dark .progress {
  height: 100%;
  border-radius: 5px;
  background-color: var(--dark-accent-color);
}

/* about.css */
.App.light .skills-section {
  width: 60vw; /* Set the width to 30vw */
  border: 2px solid var(--light-border-color); /* Add a border around the boxes */
  border-radius: 1em;
  padding: 20px; /* Add some padding inside the boxes */
}

.App.dark .skills-section {
  width: 60vw; /* Set the width to 30vw */
  border: 2px solid var(--dark-border-color); /* Add a border around the boxes */
  border-radius: 1em;
  padding: 20px; /* Add some padding inside the boxes */
}

/* Add a media query to change the layout at specific screen sizes */
@media (min-width: 800px) and (min-height: 800px) {

.centered-text {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Position the text at one-third down from the top */
  height: 100vh; /* Adjust as needed to center vertically */
}

/* about.css */
.skills {
  text-align: center;
}
.skill {
  width: 100%;
  text-align: left;
}

.App.light .progress-bar {
  height: 10px;
  background-color: var(--light-accent-opposite-color);
  border-radius: 5px;
  margin-top: 5px;
}

.App.dark .progress-bar {
  height: 10px;
  background-color: var(--dark-accent-opposite-color);
  border-radius: 5px;
  margin-top: 5px;
}

.App.light .progress {
  height: 100%;
  border-radius: 5px;
  background-color: var(--light-accent-color);
}

.App.dark .progress {
  height: 100%;
  border-radius: 5px;
  background-color: var(--dark-accent-color);
}

/* about.css */
.App.light .skills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  grid-gap: 10vw; /* Adjust the gap as needed */
  margin-left: 20vw;
  margin-right: 20vw;
  justify-items: center;
  align-items: center;
}

.App.dark .skills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  grid-gap: 10vw; /* Adjust the gap as needed */
  margin-left: 20vw;
  margin-right: 20vw;
  justify-items: center;
  align-items: center;
}

/* about.css */
.App.light .skills-section {
  width: 30vw; /* Set the width to 30vw */
  border: 2px solid var(--light-border-color); /* Add a border around the boxes */
  padding: 20px; /* Add some padding inside the boxes */
}

.App.dark .skills-section {
  width: 30vw; /* Set the width to 30vw */
  border: 2px solid var(--dark-border-color); /* Add a border around the boxes */
  padding: 20px; /* Add some padding inside the boxes */
}
}